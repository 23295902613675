<template>
  <div class="theme-floating">
    <v-system-bar v-show="$root.store.systemMessage" app height="30">
      {{$root.store.systemMessage}}
    </v-system-bar>
    <pp-app-message></pp-app-message>
    <floating-header></floating-header>
    <v-main>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view/>
      </transition>
    </v-main>
    <floating-footer></floating-footer>
    <!-- <pp-age-prompt></pp-age-prompt> -->
  </div>
</template>

<script>
  export default {
    name: 'Marketing.vue',
    data () {
      return {

      }
    },
  };
</script>

<style lang="scss">
  #app{
    background-color:#263238;    
  }
  .theme-floating{
    position: relative;
    height: 100%;
    margin: 0px;
    padding: 0px 60px 84px;
    background-color: #fff;
    border-radius: 0px;
    overflow: hidden;
    min-height: 95vh;
    @media #{$mq-tablet-down} {
      padding-left: 0px;
      padding-right: 0px;
    }
    .v-main{
      // height: calc(90vh - 109px);
      position: relative;
      z-index: 2;
      padding-bottom: 40px !important;
      @media #{$mq-tablet-down} {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
    &:before{
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 100px;
      background-color: white;
      z-index: 99;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity transform;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
    transform: translateY(-40%);

  }
</style>